




import { authorizationService } from "@/shared/services/AuthorizationService";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class cerrar_session extends Vue {
  public created() {
    this.logout();
  }
  public logout() {
    authorizationService.logout();
    this.$router.push("/login");
  }
}
